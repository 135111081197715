<template>
  <UiContainer tag="header" class="py-4.5">
    <div class="relative z-20 flex items-center justify-between">
      <h1 class="font-playfair text-xl font-semibold">
        Все <span class="italic text-text-primary">категории</span>
      </h1>
      <button v-if="smallHeader" type="button" class="pr-4" @click="smallHeader = false">
        <UiIcon name="three-dots" class="size-6 text-text-main" />
      </button>
    </div>
    <ul
      v-if="categoriesNav"
      :class="{ '!absolute -translate-y-full opacity-0': smallHeader }"
      class="z-10 mt-3 flex flex-wrap gap-3 transition-all duration-500"
    >
      <li v-for="category in categoriesNav" :key="category.id">
        <UiButton
          severity="secondary"
          tag="RouterLink"
          :to="{ name: 'CategoryItemView', params: { id: category.id } }"
          class="block first-letter:lowercase"
        >
          <span class="relative">{{ category.name }}</span>
        </UiButton>
      </li>
    </ul>
  </UiContainer>
</template>

<script lang="ts" setup>
import throttle from 'lodash/throttle'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useConfiguration } from '@/5_entities/Configuration'
import { UiButton, UiContainer, UiIcon } from '@/6_shared/ui'

const { categoriesNav } = storeToRefs(useConfiguration())
const smallHeader = ref(false)

const MIN_SCROLL = 300
const onResultsScroll = throttle(function () {
  smallHeader.value = window.scrollY >= MIN_SCROLL
})

onMounted(() => window.addEventListener('scroll', onResultsScroll))
</script>
