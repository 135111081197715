<template>
  <main class="pb-5">
    <CategoryMainHeader v-if="isMdAndDown" class="sticky left-0 top-0 z-20 mb-5 bg-primary" />
    <div v-if="categories && categoriesInitialized" class="flex flex-col gap-6">
      <SearchResults :max-items-desktop="5" :max-items-mobile="5" :value="categories" />
    </div>
    <UiContainer v-else>
      <SearchResultsCategorySkeleton v-for="skeleton in 3" :key="skeleton" class="mb-9 !px-0" />
    </UiContainer>
    <UiEmptySearch
      v-if="categories && categories.length === 0"
      class="absolute h-full justify-center inset-center"
    />
  </main>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onBeforeMount } from 'vue'
import { CategoryMainHeader } from '@/3_widgets/Category'
import { SearchResults, SearchResultsCategorySkeleton } from '@/3_widgets/Search'
import { useCategory } from '@/5_entities/Category'
import { useBreakpoint } from '@/6_shared/lib'
import { UiContainer, UiEmptySearch } from '@/6_shared/ui'

const { categories, categoriesInitialized } = storeToRefs(useCategory())
const { getAllCategories } = useCategory()
const {
  md: [_, isMdAndDown]
} = useBreakpoint()
onBeforeMount(() => getAllCategories())
</script>
